function decrypt(origin, size, key, word) {
  var s = 0;
  for (var i = 0; i < key.length; i++) {
    s = s + key.charCodeAt(i);
  }
  var pos = 0;
  var first = s % word.length;
  var prefix = "";
  var suffix = "";
  for (var i = 0; i < word.length - first; i++) {
    suffix =
      suffix +
      String.fromCharCode(
        ((word.charCodeAt(i) + size - key.charCodeAt(pos)) % size) + origin
      );
    pos = (pos + 1) % key.length;
  }
  for (var i = word.length - first; i < word.length; i++) {
    prefix =
      prefix +
      String.fromCharCode(
        ((word.charCodeAt(i) + size - key.charCodeAt(pos)) % size) + origin
      );
    pos = (pos + 1) % key.length;
  }
  var d = prefix + suffix;
  return d;
}
function navigationDropDown() {
  const header = document.querySelector("header");
  const nav = document.querySelector("header nav");
  const navToggle = document.getElementById("nav-toggle");

  document.onclick = check;
  function check(e) {
    var target = (e && e.target) || (event && event.srcElement);

    if (!checkParent(target, nav)) {
      // click NOT on the menu
      if (checkParent(target, navToggle)) {
        // click on the link
        if (header.classList.contains("show-nav")) {
          header.classList.remove("show-nav");
        } else {
          header.classList.add("show-nav");
        }
      } else {
        // click both outside link and outside menu, hide menu
        header.classList.remove("show-nav");
      }
    }
  }
  function checkParent(t, elm) {
    while (t.parentNode) {
      if (t == elm) {
        return true;
      }
      t = t.parentNode;
    }
    return false;
  }
}

document.addEventListener("DOMContentLoaded", function (event) {
  navigationDropDown();
  const contactButtons = document.getElementsByClassName("contact-me");
  // change this at http://grall.name/posts/1/onlineTools_obfuscation.html
  const mailTo = decrypt(33, 94, "vigenere", "cOZY{HXXcSVSPO_Zf$UGg&XN");
  Object.values(contactButtons).forEach((element) => {
    element.addEventListener("click", function (e) {
      window.location.assign(mailTo);
    });
  });
});

const header = document.querySelector("header");
const main = document.querySelector("main");
let minimumOffsetFromTopForStickyHeader = header.offsetTop + 20;

window.onload = function (event) {
  if (window.pageYOffset > minimumOffsetFromTopForStickyHeader) {
    header.classList.add("fixed");
    main.classList.add("offset-top");
  } else {
    header.classList.remove("fixed");
    main.classList.remove("offset-top");
  }
};

window.onscroll = function (event) {
  if (window.pageYOffset > minimumOffsetFromTopForStickyHeader) {
    header.classList.add("fixed");
    main.classList.add("offset-top");
  } else {
    header.classList.remove("fixed");
    main.classList.remove("offset-top");
  }
};
